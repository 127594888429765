import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import rehypeReact from "rehype-react"

import { typography } from "../theme"

import SEO from "../components/seo"
import Layout, { CenterPage } from "../components/Layout"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Collapsible from "../components/Collapsible"

const Content = styled.div`
  ${typography}
`;

const Timestamp = styled.div`
  margin-bottom: 24px;
  p {
    font-size: 16px;
  }
`;

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { 'collapsible': Collapsible },
}).Compiler

export default function Template({
  data,
}) {
  const { markdownRemark } = data
  const { frontmatter, htmlAst } = markdownRemark

  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <Header bgColor="TEAL" />
        <CenterPage>
          <Content>
            <h1>{frontmatter.title}</h1>
            <>
              <Timestamp>
                <strong>Updated:</strong> {frontmatter.date}
              </Timestamp>
              {renderAst(htmlAst)}
            </>
          </Content>
        </CenterPage>
      <Footer bgColor="BLACK" />
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      htmlAst
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
      }
    }
  }
`