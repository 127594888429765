import React from "react"
import styled from "styled-components"

import { color, media } from "../../theme"

const Content = styled.div`
  max-height: 0px;
  overflow: hidden;
  transition: max-height .25s ease-in-out;
`

const Wrapper = styled.div`
  ${props => props.first && `
    margin-top: 1rem;
  `}
  border-top: 1px solid ${color.LIGHT_GRAY};
  border-bottom: 1px solid ${color.LIGHT_GRAY};
  margin-bottom: -1px;
  & > input[type='checkbox']{
    position: absolute; /* take input out of flow... */
    visibility: hidden; /* use visibility not display: none to allow in-page link */
    &:checked + label + .collapsible-content {
      max-height: 200vh;
    }
    &:checked + label::after {
      transform: rotate(90deg) translateX(-3px);
    }

    &:checked + label {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

  }
  & > label {
    display: block;
    font-weight: bold;
    font-size: 1.2rem;
    color: ${color.LIGHT_TEAL};
    padding: 1rem 0;
    cursor: pointer;
    transition: all 0.25s ease-out;
    position: relative;
    padding-right: 3rem;
  &:hover {
    color: ${color.TEAL};
  }
  &::after {
    content: ' ';
    position: absolute;
    display: inline-block;
    right: 1.5rem;
    top: 50%;
    margin-top: -3px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid currentColor;
    vertical-align: middle;
    margin-left: .7rem;
    transform: translateY(-2px);
    transition: transform .2s ease-out;
  }
}
`

const Inner = styled.div`
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: 0.5rem 0;
  & th {
    width: auto;
  }
  & th + th {
    width: 66%;
    padding-right: 0;
    ${media.md`
      width: 75%;
    `};
  }
  & td + td {
    padding-right: 0;
  }
`;

const hashMatch = (id) => {
  if (typeof window !== 'undefined') {
    if (window.location.hash.substring(1) === id) {
      return true;
    }
  }
  return false;
};

const Collapsible = ({ first, heading, children }) => {
  const id = heading.replace(/\s/g, '-').replace(/\?/g, '').toLowerCase();

  /**
   * initially, (when the page loads)...
   * if url hash corresponds to id of this collapsible,
   * set it's input to checked
   */
  const [isChecked, setIsChecked] = React.useState(hashMatch(id));

  /**
   * set a hashchange listener for internal links
   * for example, clicking a link in an faq to another faq
   */
  React.useEffect(() => {
    if (!window) return false;
    const handleHashChange = () => {
      setIsChecked(hashMatch(id));
    }

    window.addEventListener('hashchange', handleHashChange); 

    return () => {
      window.removeEventListener('hashchange', handleHashChange)
    }
  }, [id])
  
  /**
   * always clear a hash when a input is checked to prevent
   * page from repositioning
   */
  const handleOnChange = React.useCallback((e) => {
    // reset url (clear the hash without keeping #)
    window.history.pushState('', document.title, window.location.pathname
      + window.location.search);
    setIsChecked(checked => !checked);
  }, []);

  return (
    <Wrapper first={first}>
      <input
        id={id}
        className="toggle"
        type="checkbox"
        onChange={handleOnChange}
        checked={isChecked}
      />
      <label htmlFor={id} className="lbl-toggle">{heading}</label>
        <Content className="collapsible-content">
          <Inner>
            {children}
          </Inner>
        </Content>
    </Wrapper>
  )
}

export default Collapsible
